.scroll-to-top {
  // background-color: #ffffff00;
  // box-shadow: none;
  // right: 18px;
}

button:focus {
  outline: none;
}
.scrolltop {
  // position: absolute;
  // right: 0;
  // margin-top: -5rem;
  // padding: 1rem;
  // border: none;
  // border-radius: 100%;
  // background-color: aqua;
  // color:black;
}
