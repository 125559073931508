// .searchbar {
//     margin-right: 10px;

// }

.search-filter {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 15px 15px 0 0;
    justify-content: flex-end;
    gap: 10px
}

.css-16c50h-MuiInputBase-root-MuiTablePagination-select {
    margin-bottom: 15px;
}

.css-jtlhu6-MuiTablePagination-root {
    height: 30px;
}

.MuiTablePagination-actions {
    margin-top: -15px;
}

.edit-action {
    display: flex;
    flex-direction: column;
}

.css-177gid-MuiTableCell-root {
    text-align: center;
}

.add-icon {
    position: fixed;
    width: -moz-fit-content;
    width: fit-content;
    right: 77px;
    bottom: 10px;
}

.edit-delete {
    display: flex;
    // flex-direction: column;
    align-items: center;
    justify-content: center
}

.edit-delete .css-i4bv87-MuiSvgIcon-root {
    height: 0.75em;
    width: 0.75em;
    color: rgb(27 55 121);
}

.css-1yhpg23-MuiTableCell-root {
    padding: 5px 9px !important;

}

.css-1yhpg23-MuiTableCell-root {
    border-bottom: 0.5px solid rgba(224, 224, 224, 1) !important;
}

.css-34nofg-MuiTableRow-root:hover {
    background: #ddd;
    cursor: pointer;
}

.css-1ndpvdd-MuiTableCell-root {
    padding: 12px !important;
}