.slider__wrapper {
    background: #33415c;
    padding: 20px 0;
    position: relative;
    display: flex;
    flex-direction: column-reverse;
    margin-top: 10px;
}

@media only screen and (min-width: 1200px) {
    .container {
        max-width: 1470px;
    }

    .slider__wrapper {
        max-width: 1720px;
        margin: 0 auto;
        border-radius: 30px;
    }
}

.slider__inner {
    color: #fff;
    margin-top: 70px;
    display: block;
}

.slider__wrapper .single__slider {
    padding-left: 16px;
    //text-align: center;
}

.slider__wrapper .single__slider {
    padding-left: 16px;
}

.single__slider h1 {
    padding: 10px 10px 2px 0;
}

.slider__inner .btn.video__btn {
    border: 1px solid #ffab4a;
    width: 65px;
    height: 65px;
    font-size: 25px;
    color: #ffab4a;
    background: #33415c;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
}

.slider__inner .btn.video__btn:hover {
    border: 1px dashed;
    color: #fff;
}

.single__slider .above__title {
    font-size: 48px;
    font-weight: 600;
}

.slider__inner .slider__para {
    padding: 20px 0 38px 0;
}

.slider__inner .slider__para {
    padding: 8px 0 28px 0;
}

.slider__btns a img {
    margin-left: 15px;
    transition: all linear 0.2s;
    -webkit-transition: all linear 0.2s;
    -moz-transition: all linear 0.2s;
}

.row__reverce {
    display: flex;
    flex-direction: column-reverse;
}

.slider__inner .slick-prev,
.slider__inner .slick-next {
    top: 112%;
}

.slick__arrow__prev:hover,
.slick__arrow__next:hover {
    background: #ff564f;
}

.slick__arrow__next {
    position: absolute;
    left: 30%;
    // bottom: -46px;
    margin: 0 -10px;
    background: #242d3e;
    height: 45px;
    width: 45px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.slick__arrow__prev {
    position: absolute;
    left: 10%;
    bottom: -46px;
    margin: 0 -10px;
    background: #242d3e;
    height: 45px;
    width: 45px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.slider__inner .slick-prev {
    left: 18%;
}

.slider__inner .slick-next {
    right: 83%;
}

.slider__inner .slick-prev,
.slider__inner .slick-next {
    z-index: 333;
    top: 120%;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    height: 30px;
    width: 30px;
}

.slider__inner .cmn__btn:hover img {
    transform: translateX(5px);
    -webkit-transform: translateX(5px);
    -moz-transform: translateX(5px);
}

.slider__inner .cmn__btn:hover::after {
    transform: scaleX(1);

}

.slider__inner .learn__btn {
    margin-left: 30px;
}

.slider__inner .cmn__btn:after {
    position: absolute;
    font-family: "Font Awesome 5 Free";
    font-weight: 400;
    content: "";
    background: #564fff;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    ;
    z-index: -1;
    transform: scaleX(0);
    transform-origin: 0 50%;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    transition-property: transform;
    transition-timing-function: ease-out;
    transition-duration: 0.2s;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 93px;

}

.slider__side__color {
    position: absolute;
    top: 33%;
    left: -2%;
}

.slider__side__color1 {
    position: absolute;
    top: 15%;
    right: 50%;
}

.slider__inner .cmn__btn {
    color: #fff;
    position: relative;
    z-index: 222;
}

.slider__inner .cmn__btn:hover {
    border-color: #564fff !important;
}

.cmn__btn {
    background: transparent;
    width: 165px;
    height: 50px;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    display: inline-flex;
    border: 1px solid #fff;
}

a,
a:focus,
a:hover {
    text-decoration: none;
    outline: 0;
}

.image-width {
    max-width: 35% !important;
}

.image-width1 {
    max-width: 10% !important;
}

.slider__btns a img {
    margin-left: 15px;
    transition: all linear 0.2s;
    -webkit-transition: all linear 0.2s;
    -moz-transition: all linear 0.2s;
}

.single__slider h1 {

    font-size: 70px;
    line-height: 70px;
    display: block;
    padding: 10px 10px 2px;

}

.video__btn {
    img {
        width: 0.9em;
        margin-left: 11px;
    }
}

.img-fluid {
    max-width: 92%;
    height: auto;
}

.slider__btns {
    margin-top: 15px;
}

.school-info {
    font-weight: 400;
    font-family: emoji;
    margin-left: 43px;

    p {

        margin: 0px;
        font-size: 23px;
        color: #f07b47;
        margin-top: 1rem;
        padding-left: 38px;
    }
}

.writer-title {
    text-align: right;
    font-style: oblique;
    font-size: 16px;
    margin-top: 20px;
    color: white;
}