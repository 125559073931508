.card-items {
    margin-bottom: 60px;

    img {
        height: 157px;
    }
}

.quick-link-title {
    display: flex;
    justify-content: center;
    margin: 50px;
    text-align: center;

}


.all-line-2 {
    margin-top: -33px;
    margin-right: 59px;

    .first-line {
        border: 1px solid #f07b47;
        display: inline-block;
        background: #f07b47;
        border-radius: 8px;
        width: 20px;
    }

    .middle-line {
        display: inline-block;
        height: 6px;
        border: 1px solid #f07b47;
        margin: 0px 0px;
        margin-bottom: -2px;
        width: 42px;
        background: #f07b47;
        border-radius: 6px;
    }

    .last-line {
        width: 41px;
    }

}