.font {
  font-size: 20px;
}

.button {
  padding: 40px 0 0 0;
}

.divStyle {
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 1281px; */
  height: 80%;
}

.imageStyles {
  height: 100vh;
  background-image: url("../../../assets/Images/loginimg.png");
  background-repeat: no-repeat;
  background-size: contain;
}
