.language-list {
    font-size: 12px;
    font-weight: 500;
    margin-left: 30px;

    &:hover {
        color: #564fff;
    }
}

// .MuiList-root {
//     max-width: 120px;
// }