.MuiInputLabel-animated {
  font-size: 14px !important;
}

.AddAdmitStudent-field-1 {
  margin: 0px 0 !important;
}

.form-control {
  padding: 0 0 !important;
  border: 0 !important;
}

.css-1aquho2-MuiTabs-indicator {
  background-color: #1b3779 !important;
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #1b3779 !important;
}

.css-34wlu5-MuiButtonBase-root-MuiCheckbox-root {
  padding: 4px !important;
}

.css-1ex1afd-MuiTableCell-root {
  padding: 8px 14px !important;
}

.css-1ygcj2i-MuiTableCell-root {
  padding: 10px 14px !important;
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  text-transform: capitalize !important;
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  text-transform: capitalize !important;
}

.css-mhc70k-MuiGrid-root > .MuiGrid-item {
  padding-right: 16px !important;
}

.css-1aquho2-MuiTabs-indicator {
  height: 3px !important;
}

.App {
  width: 100%;
}

.Toastify__toast-container--top-right {
  width: -moz-fit-content;
  width: fit-content;
}

.side-item {
  margin-bottom: 2px;
  & > div {
    align-items: flex-start;
  }
}

//temp fix
:root {
  --toastify-color-light: #fff;
  --toastify-color-dark: #121212;
  --toastify-color-info: #3498db;
  --toastify-color-success: #07bc0c;
  --toastify-color-warning: #f1c40f;
  --toastify-color-error: #e74c3c;
  --toastify-color-transparent: rgba(255, 255, 255, 0.7);
  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);
  --toastify-toast-width: 320px;
  --toastify-toast-background: #fff;
  --toastify-toast-min-height: 64px;
  --toastify-toast-max-height: 800px;
  --toastify-font-family: sans-serif;
  --toastify-z-index: 9999;
  --toastify-text-color-light: #757575;
  --toastify-text-color-dark: #fff;
  --toastify-text-color-info: #fff;
  --toastify-text-color-success: #fff;
  --toastify-text-color-warning: #fff;
  --toastify-text-color-error: #fff;
  --toastify-spinner-color: #616161;
  --toastify-spinner-color-empty-area: #e0e0e0;
  --toastify-color-progress-light: linear-gradient(
    to right,
    #4cd964,
    #5ac8fa,
    #007aff,
    #34aadc,
    #5856d6,
    #ff2d55
  );
  --toastify-color-progress-dark: #bb86fc;
  --toastify-color-progress-info: var(--toastify-color-info);
  --toastify-color-progress-success: var(--toastify-color-success);
  --toastify-color-progress-warning: var(--toastify-color-warning);
  --toastify-color-progress-error: var(--toastify-color-error);
}

.App .MuiGrid-spacing-xs-2 {
  width: calc(100% + 8px);
  padding-left: 8px;
}
