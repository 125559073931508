.latest-event-content .react-multi-carousel-item {
  margin-left: 2px;
}

.latest-event-content {
  margin-bottom: 60px;
  margin-top: 60px;
  background-color: rgb(51, 65, 92);
  padding-bottom: 30px;
  padding-top: 30px;

  h1 {
    display: flex;
    justify-content: center;

    color: #fff;
    text-transform: uppercase;
    padding-bottom: 30px;
  }
}

.underline1 {
  border-bottom: 2px solid rgb(116, 36, 36);
  border-width: 90%;
  width: 80px;
}

.first-line {
  border: 1px solid #f07b47;
  display: inline-block;
  background: #f07b47;
  border-radius: 8px;
  width: 20px;
}

.middle-line {
  display: inline-block;
  height: 6px;
  border: 1px solid #f07b47;
  margin: 0px 0px;
  margin-bottom: -2px;
  width: 42px;
  background: #f07b47;
  border-radius: 6px;
}

.last-line {
  width: 41px;
}

.all-line1 {
  margin-top: -39px;
}
