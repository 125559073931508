.tc-title {
    background: black;
    color: #fff;
    font-size: 30px;
    font-weight: bold;
}

.address-in-certificate {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 25px;
}

.note-in-certificate {
    width: 70%;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 25px;
}

.schl-name {
    font-size: 30px;
    font-weight: 500;
}

.excellent {
    font-size: 40px;
    font-weight: bold;
}

.certificate {
    font-size: 30px;
    font-weight: bold;
}

.certif-info {
    display: flex;
    justify-content: center;
}

.tc-details {
    display: flex;
    justify-content: space-between;
    margin: 16px;
    align-items: flex-end;
}

.tc-container {
    width: 80%;
}

.tc-sign {
    display: flex;
    justify-content: space-around;
    margin-top: 30px;
    font-size: 20px;
    font-weight: 600;
}

.main-container {
    display: flex;
    justify-content: center;
    margin-top: 30px;
}

.tc-inputs {
    width: 530px;
    display: flex;
}

.tc-label {
    text-align: left;
    margin-left: 130px;
    width: 400px;
    line-break: auto;
}

@media screen and (max-width: 900px) {
    .tc-details {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        /* align-items: center; */
        // height: 40px;
        margin: 16px;
    }

    .tc-label {
        text-align: left;
        margin-left: 0px;
        width: 400px;
        line-break: auto;
    }
}

@media scree and (max-width: 1200px) {

    .main-container {
        display: block;

    }

}