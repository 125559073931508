.scroll-container1 {
  /* border: 3px solid black; */
  border-radius: 5px;
  /* height: 50%; */
  /* overflow: hidden; */
  /* width: 50%; */
  margin: 18px;

  -moz-animation: my-animation1 100s linear infinite;
  -webkit-animation: my-animation1 100s linear infinite;
  animation: my-animation1 50s linear infinite;
  animation-play-state: running;
}

.scroll-container1:hover {
  /* border: 3px solid black; */
  border-radius: 5px;
  margin: 18px;

  -moz-transform: translateY(100%);
  -webkit-transform: translateY(100%);
  transform: translateY(100%);

  -moz-animation: my-animation1 100s linear infinite;
  -webkit-animation: my-animation1 100s linear infinite;
  animation: my-animation1 100s linear infinite;
  animation-play-state: paused;

  .title-discription1:hover {
    text-decoration: underline;
  }
}

.news-container1 {
  overflow: hidden;
  // display: flex;
}

.news-title {
  // width: 50%;
  margin-left: 18px;
  // display: flex;
  justify-content: space-around;
}

.news-content1 {
  background-color: #f7f6f2;
  border: 1px solid #ddd;
  margin-bottom: 10px;
  display: flex;
  height: auto;
  width: 95%;
  margin-left: 2rem;
  padding: 0px 1px;
  min-height: 100px;
  position: relative;
}

.scroll-text1 {
  text-align: center;
  /* animation properties */
}

/* for Firefox */
@-moz-keyframes my-animation1 {
  from {
    -moz-transform: translateY(0);
  }

  to {
    -moz-transform: translateY(-100%);
  }
}

/* for Chrome */
@-webkit-keyframes my-animation1 {
  from {
    -webkit-transform: translateY(0);
  }

  to {
    -webkit-transform: translateY(-100%);
  }
}

@keyframes my-animation1 {
  from {
    -moz-transform: translateY(0);
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  to {
    -moz-transform: translateY(-100%);
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
}

.achievements-conatiner {
  .react-multi-carousel-item {
    width: 321px !important;
  }

  .MuiPaper-root {
    max-width: 309px !important;
  }
}

.news-awards {
  width: 50%;
  height: 333px;

  h1 {
    text-align: center;
  }
}

.achievents {
  width: 50%;

  h1 {
    text-align: center;
  }
}

.news-and-notice-conatiner {
  display: flex;

  margin-bottom: 15px;
}

.react-multiple-carousel__arrow {
  z-index: 100 !important;
}

.news-date-month1 {
  background: #f07b47;
  // width: 40px;
  // height: 80%;
  width: 4rem;
  height: 53px;
  margin-left: -2rem;
  top: 15px;
  position: absolute;
  vertical-align: middle;
  color: #fff;
}

.title-discription1 {
  padding: 5px 2rem;
  margin-left: 6px;
  & h4 {
    color: #f58322;
    text-align: left;
  }
}

.discription1 {
  font-size: 14px;
  height: 38px;
  overflow: hidden;
  display: flex;
  justify-content: flex-start;
}

.date {
  border-bottom: 1px solid #fff;
  width: 50%;
  margin: auto;
}

.all-line-3 {
  margin-top: -39px;
  // margin-right: 248px;

  .first-line {
    border: 1px solid #f07b47;
    display: inline-block;
    background: #f07b47;
    border-radius: 8px;
    width: 41px;
  }

  .middle-line {
    display: inline-block;
    height: 6px;
    border: 1px solid #f07b47;
    margin: 0px 0px;
    margin-bottom: -2px;
    width: 42px;
    background: #f07b47;
    border-radius: 6px;
  }

  .last-line {
    width: 41px;
  }
}

.all-line-4 {
  margin-top: -39px;
  //margin-right: 404px;

  .first-line {
    border: 1px solid #f07b47;
    display: inline-block;
    background: #f07b47;
    border-radius: 8px;
    width: 41px;
  }

  .middle-line {
    display: inline-block;
    height: 6px;
    border: 1px solid #f07b47;
    margin: 0px 0px;
    margin-bottom: -2px;
    width: 42px;
    background: #f07b47;
    border-radius: 6px;
  }

  .last-line {
    width: 41px;
  }
}
