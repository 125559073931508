

.post-content {
    padding-left: 80px;
    margin-top: 10px;
}

* {
    margin: 0;
    padding: 0;
    text-decoration: none;
}

.sidebar-container {
    display: flex;
    width: 100%;
    padding-right: 0px;
    padding-left: 0px;
    margin-right: auto;
    margin-left: auto;
    max-height: 100vh;
    overflow: auto;
}

main {
    width: 100%;
    overflow-y: auto;
}

.sidebar {
    background: #1b3779;
    color: #fff;
    // height: 100vh;
    width: 200px;
    transition: all 0.5s;

}

.top_section {
    display: flex;
    align-items: center;
    padding: 10px 11px;
}

.logo {
    height: 80px;
}

.bars {
    display: flex;
    font-size: 25px;
    margin-left: 50px;
}

.main-link {
    display: flex;
    color: #fff;
    padding: 10px 15px;
    gap: 15px;
    transition: all 0.5s;
    cursor: pointer;
}

.icon,
.link_text {
    font-size: 16px;

    a {
        color: #fff;
    }
}

.css-hyum1k-MuiToolbar-root {
    background: #fff;
    color: green;
    text-align: center;
}

.submenu-content {
    display: flex;
    align-items: center;
    gap: 2px;
    margin-left: -30px;

}

.submenu-list-icon {
    color: #fff;
}

.submenu-list {
    font-size: 14px;
    color: #fff;
    font-weight: 400;
    margin: 6px;
}

.sidebar-menus {
    height: auto;
    overflow: auto;
    overflow-x: hidden;
}