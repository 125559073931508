.slider-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-bottom: 60px;
    & .slider-item {
        position: relative;
        & .images-container {
            position: absolute;
            top: -75px;
            left: 0;
            display: flex;
            justify-content: space-between;
            width: 100%;
        }
    }
}

.birthday-title h1 {
    display: flex;
    justify-content: center;
    height: 70px;
    align-items: center;
    margin-bottom: 80px;
    font-weight: 700;
    text-shadow: 2px 2px #ddd;
}


.birthday-today {
    h1 {
        margin-bottom: 30px;
        font-size: revert;
        text-shadow: 3px 3px #ddd;
    }
}

.slider-item1 {
    border: 14px solid #33415c;
    border-radius: 25px;
    border-bottom-width: 30px !important;
}

.birthday-today {
    font-weight: 600;
    align-items: center;
}

.bg-img-bday {
    height: auto;
    width: 180px;
    z-index: 99;
}

.bg-img-bday1 {
    height: auto;
    width: 180px;
    z-index: 99;
}

.birthday-title {
    position: relative;
}

.list-item-color {
    max-height: 350px;
    overflow: hidden;
}

.cake-bg {
    height: 120px;
    width: 180px;
    position: absolute;
    z-index: 99;
}

.dot-img {
    position: relative;
    top: 39px;
    right: 12px;
}

@-moz-keyframes my-animation {
    from {
        -moz-transform: translateY(100%);
    }

    to {
        -moz-transform: translateY(-100%);
    }
}

/* for Chrome */
@-webkit-keyframes my-animation {
    from {
        -webkit-transform: translateY(100%);
    }

    to {
        -webkit-transform: translateY(-100%);
    }
}

@keyframes my-animation {
    from {
        -moz-transform: translateY(100%);
        -webkit-transform: translateY(100%);
        transform: translateY(100%);
    }

    to {
        -moz-transform: translateY(-100%);
        -webkit-transform: translateY(-100%);
        transform: translateY(-100%);
    }
}


.bday-img {
    position: relative;
    height: 61px;
    top: -10px;
}

.d-flex {
    max-height: 350px;
    overflow: auto;
    height: 300px;

}

.scroll-b-t {
    -moz-transform: translateY(100%);
    -webkit-transform: translateY(100%);
    transform: translateY(100%);

    -moz-animation: my-animation 15s linear infinite;
    -webkit-animation: my-animation 15s linear infinite;
    animation: my-animation 15s linear infinite;
}

.scroll-b-t:hover {
    -moz-transform: translateY(100%);
    -webkit-transform: translateY(100%);
    transform: translateY(100%);

    -moz-animation: my-animation 8s linear infinite;
    -webkit-animation: my-animation 8s linear infinite;
    animation: my-animation 8s linear infinite;
    animation-play-state: paused;
}

.bday-autoscroll-list {
    height: 400px;

}