.css-aqpgxn-MuiFormLabel-root-MuiInputLabel-root {
  font-size: 12px !important;
}

.divStyle {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 80%;
}

.imageStyles {
  height: 100vh;

  background-image: url("../../../assets/Images/school3.png");

  -webkit-background-size: cover;

  background-repeat: no-repeat;

  background-size: cover;
}

.rememberStyle {
  font-size: smaller;
  font-weight: 500;
  padding-left: 100;
}

.errorStyle {
  color: red;
}
