.contact-list {
  list-style: none;
  padding: 0;
  font-size: 14px;
}
.phoneI {
  color: #564fff;
}
.phone {
  margin-top: -27px;
}
.emailI {
  color: #564fff;
}
.email {
  margin-top: -27px;
}
.addressI {
  color: #564fff;
}
.address {
  margin-top: -26px;
  width: 350px;
}

.information-list {
  list-style: none;
  padding: 0;
}

.gallery img {
  height: 100%;
  width: 100%;
  border-radius: 10px;
}

.gallery {
  height: 70px;
  width: 70px;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 5px;
}
.gallery-list {
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  width: 320px;
  height: 170px;
}
// .container{
//     list-style: none;
//     padding: 0;
//     width: 140px;
//     height: 10px;
//     background-color: rgb(17, 0, 255);
// }

.footer-flex-container {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 20px;
}

.contact-us-container {
  width: 33.33%;
  margin: 20px;
}

.information-container {
  width: 33.33%;
  margin: 20px;
}

.gallery-container {
  width: 33.33%;
  margin: 20px;
}
footer {
  background: #383838;
  padding: 0px 50px 10px 50px;
  & .footer-message {
    color: #ffffff;
    & a {
      color: #ffffff;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
.footer-flex-container {
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  padding-top: 20px;
}

.gallery-view-more-button {
  text-align: right;

  .MuiButtonBase-root {
    bottom: 15px;
    font-size: 10px;
    color: #f07b47;
  }
}
.footermenu {
  // flex-grow: 1;
  // display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100px;
  // background-color: pink;
  width: 150px;
  margin-top: 35px;
  margin-left: -30px;
}
.footermenu ul li a {
  display: flex;
  flex-wrap: wrap;
  height: 4px;
  font-weight: 400;
  font-size: 15px;
  margin: 0px 10px;
  padding: 20px 0px;
  color: #fff;
  cursor: pointer;
}

.footermenu ul li {
  list-style-type: none;
}
.footermenu ul li a:hover {
  color: #564fff;
}
.footermenu2 {
  // flex-grow: 1;
  // display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100px;
  // background-color: pink;
  width: 300px;
  margin-top: -100px;
  margin-left: 120px;
}
.footermenu2 ul li a {
  display: flex;
  flex-wrap: wrap;
  height: 4px;
  font-weight: 400;
  font-size: 15px;
  margin: 0px 10px;
  padding: 20px 0px;
  color: #fff;
  cursor: pointer;
}

.footermenu2 ul li {
  list-style-type: none;
}
.footermenu2 ul li a:hover {
  color: #564fff;
}
.foot {
  margin-bottom: -20px;
}
.footer-message {
  font-size: 15px;
  font-weight: 300;
  margin-left: -20px;
}
.popup-image {
  display: flex;
  align-items: center;
  justify-content: center;
}
.Ricon {
  color: #fff;
}
.Licon {
  color: #fff;
}
