.social-item {
  position: fixed;
  top: 400px;
  right: 7px;
  width: 59px;
  z-index: 999;
}

.social-item {
  .MuiButtonBase-root {
    background-color: #f07b47;
  }
}
